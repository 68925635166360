import { type PreviewConfig } from '../types.js';

// TODO: Update this file with the correct preview config for this template.
export const previewConfig: PreviewConfig = {
  images: {
    set: {
      dawn: 'set_01',
      dusk: 'set_01',
      midday: 'set_01',
      midnight: 'set_01',
      morning: 'set_02',
    },
    useHighRes: true,
  },
  projects: {
    quantity: 4,
  },
  services: {
    quantity: 2,
  },
};
