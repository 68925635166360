import { templateThemeTokens } from './templateThemeTokens.js';
import { fontStacks } from '@/stitches/fonts.js';
import { createTheme } from '@/stitches/index.js';
import { merge } from 'lodash-es';

const mergedTokens = merge(templateThemeTokens, {
  colors: {
    defaultFont: '#263B1B',
    pageBackground: '#E9FBFF',
  },
  fonts: {
    body: fontStacks.epilogue.value,
    heading: fontStacks.epilogue.value,
  },
});

export const dawn = createTheme({ ...mergedTokens });
