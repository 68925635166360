import {
  AllThemeNames,
  type TemplateNames,
  type ThemeNames,
} from '@/templates/templateTypes.js';

/**
 * isSupportedThemeSlug helpers to validate that a template or theme coming back from the
 * API is actually registered in the application and supported. This ensures we don't
 * accidentally show users options that have not actually been developed/created in the application
 * that would lead to an application crash.
 */
export const isSupportedThemeSlug = (
  themeDevelopmentSlug: TemplateNames | string,
): themeDevelopmentSlug is ThemeNames => {
  return AllThemeNames.includes(themeDevelopmentSlug as ThemeNames);
};
