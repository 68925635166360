import { templateThemeTokens } from './templateThemeTokens.js';
import { THEME_DEFAULT_FONTS } from '@/contexts/CustomThemeConfigContext/constants.js';
import { createTheme } from '@/stitches/index.js';
import { merge } from 'lodash-es';

const themeObject = merge(templateThemeTokens, {
  colors: {
    defaultFont: '#FFF',
    pageBackground: '#000',
    secondaryFont: '#F0FB96',
    sectionBackground: '#000',
  },
  fonts: {
    accent: THEME_DEFAULT_FONTS.barcelona.midnight.accent
      ? THEME_DEFAULT_FONTS.barcelona.midnight.accent.value
      : 'unset',
    body: THEME_DEFAULT_FONTS.barcelona.midnight.body.value,
    heading: THEME_DEFAULT_FONTS.barcelona.midnight.headline.value,
  },
  fontWeights: {
    heading: 400,
  },
});

export const midnight = createTheme({ ...themeObject });
