import { graphql } from 'relay-runtime';

export const independentPortfolioTemplatesFragment = graphql`
  fragment IndependentPortfolioTemplates_queryFragment on Query {
    independentPortfolioTemplates {
      edges {
        node {
          id
          displayName
          developmentSlug
          isLive
          accessLevel
          themes {
            edges {
              node {
                id
                displayName
                developmentSlug
                isLive
                generatedPreviewImage
                previewImage {
                  id
                  uid
                }
              }
            }
          }
        }
      }
    }
  }
`;
