import { fontStacks } from '@/stitches/fonts.js';
import { sharedThemeTokens } from '@/templates/sharedThemeTokens.js';
import { pxToRem } from '@/utilities/pxToRem.js';
import { merge } from 'lodash-es';

export const templateThemeTokens = merge(sharedThemeTokens, {
  fonts: {
    body: fontStacks.workSans.value,
    cabinet: fontStacks.cabinet.value,
    epilogue: fontStacks.epilogue.value,
    heading: fontStacks.clashDisplay.value,
    neco: fontStacks.neco.value,
    switzer: fontStacks.switzer.value,
  },
  lineHeights: {
    body: pxToRem(22.4),
  },
});
