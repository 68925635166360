import {
  AllTemplateNames,
  type TemplateNames,
} from '@/templates/templateTypes.js';

/**
 * isSupportedTemplateSlug helpers to validate that a template or theme coming back from the
 * API is actually registered in the application and supported. This ensures we don't
 * accidentally show users options that have not actually been developed/created in the application
 * that would lead to an application crash.
 */
export const isSupportedTemplateSlug = (
  templateDevelopmentSlug: TemplateNames | string,
): templateDevelopmentSlug is TemplateNames => {
  return AllTemplateNames.includes(templateDevelopmentSlug as TemplateNames);
};
