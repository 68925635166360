import { isSupportedTemplateSlug } from './isSupportedTemplateSlug.js';
import { isSupportedThemeSlug } from './isSupportedThemeSlug.js';
import {
  type TemplateNames,
  type ThemeNames,
} from '@/templates/templateTypes.js';

export const getStyleOverride = (
  overrideParameter: string[] | string,
): [TemplateNames | undefined, ThemeNames | undefined] => {
  const styleOverrideString =
    (Array.isArray(overrideParameter)
      ? overrideParameter[0]
      : overrideParameter) ?? '';
  const [overrideTemplate, overrideTheme] = styleOverrideString.split(':');

  if (!overrideTemplate || !overrideTheme) return [undefined, undefined];

  const templateIsValid = isSupportedTemplateSlug(overrideTemplate);
  const themeIsValid = isSupportedThemeSlug(overrideTheme);

  if (templateIsValid && themeIsValid) {
    return [overrideTemplate, overrideTheme];
  }

  return [undefined, undefined];
};
