import { templateThemeTokens } from './templateThemeTokens.js';
import { fontStacks } from '@/stitches/fonts.js';
import { createTheme } from '@/stitches/index.js';
import { merge } from 'lodash-es';

const mergedTokens = merge(templateThemeTokens, {
  colors: {
    defaultFont: '#A41109',
    pageBackground: '#fef9f9',
  },
  fonts: {
    body: fontStacks.generalSans.value,
    heading: fontStacks.lora.value,
  },
});

export const dawn = createTheme({ ...mergedTokens });
