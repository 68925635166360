import { type CanvasOptions } from '@/components/Canvas/types.js';

export const canvasOptions: CanvasOptions = {
  dawn: {
    config: null,
    enable: false,
  },
  dusk: {
    config: null,
    enable: true,
  },
  midday: {
    config: null,
    enable: false,
  },
  midnight: {
    config: null,
    enable: false,
  },
  morning: {
    config: null,
    enable: false,
  },
};
