export const AllTemplateNames = [
  'losAngeles',
  'barcelona',
  'sydney',
  'paris',
  'tokyo',
  'madrid',
  'london',
  'saoPaulo',
  'amsterdam',
  'capeTown',
] as const;

export const AllThemeNames = [
  'dawn',
  'morning',
  'midday',
  'dusk',
  'midnight',
] as const;

export type TemplateNames = (typeof AllTemplateNames)[number];
export type ThemeNames = (typeof AllThemeNames)[number];

export const isValidTheme = (theme: string): theme is ThemeNames => {
  return (AllThemeNames as readonly string[]).includes(theme);
};

export const isValidTemplate = (
  template: string,
): template is TemplateNames => {
  return (AllTemplateNames as readonly string[]).includes(template);
};
