import { templateThemeTokens } from './templateThemeTokens.js';
import { THEME_DEFAULT_FONTS } from '@/contexts/CustomThemeConfigContext/constants.js';
import { createTheme } from '@/stitches/index.js';

export const dawn = createTheme({
  ...templateThemeTokens,
  colors: {
    defaultFont: '$brandBlack',
    pageBackground: '$white',
  },
  fonts: {
    accent: THEME_DEFAULT_FONTS.sydney.dawn.accent
      ? THEME_DEFAULT_FONTS.sydney.dawn.accent.value
      : 'unset',
    body: THEME_DEFAULT_FONTS.sydney.dawn.body.value,
    heading: THEME_DEFAULT_FONTS.sydney.dawn.headline.value,
  },
});
