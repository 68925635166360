import { templateThemeTokens } from './templateThemeTokens.js';
import { THEME_DEFAULT_FONTS } from '@/contexts/CustomThemeConfigContext/constants.js';
import { createTheme } from '@/stitches/index.js';

export const morning = createTheme({
  ...templateThemeTokens,
  colors: {
    defaultFont: '#000000',
    pageBackground: '#DCE3EB',
    secondaryFont: '#F27419',
  },
  fonts: {
    accent: THEME_DEFAULT_FONTS.paris.morning.accent
      ? THEME_DEFAULT_FONTS.paris.morning.accent.value
      : 'unset',
    body: THEME_DEFAULT_FONTS.paris.morning.body.value,
    heading: THEME_DEFAULT_FONTS.paris.morning.headline.value,
  },
  fontWeights: {
    body: 400,
    heading: 400,
  },
});
