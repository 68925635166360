import { templateThemeTokens } from './templateThemeTokens.js';
import { THEME_DEFAULT_FONTS } from '@/contexts/CustomThemeConfigContext/constants.js';
import { createTheme } from '@/stitches/index.js';
import { merge } from 'lodash-es';

const mergedTokens = merge(templateThemeTokens, {
  colors: {
    defaultFont: '$brandBlack',
    pageBackground: '#F0FB96',
    secondaryFont: '#F7FDCA',
  },
  fonts: {
    accent: THEME_DEFAULT_FONTS.madrid.morning.accent
      ? THEME_DEFAULT_FONTS.madrid.morning.accent.value
      : 'unset',
    body: THEME_DEFAULT_FONTS.madrid.morning.body.value,
    heading: THEME_DEFAULT_FONTS.madrid.morning.headline.value,
  },
});

export const morning = createTheme({ ...mergedTokens });
