import { templateThemeTokens } from './templateThemeTokens.js';
import { THEME_DEFAULT_FONTS } from '@/contexts/CustomThemeConfigContext/constants.js';
import { createTheme } from '@/stitches/index.js';

export const morning = createTheme({
  ...templateThemeTokens,
  colors: {
    defaultFont: '#343333',
    pageBackground: '#E7E5DC',
  },
  fonts: {
    accent: THEME_DEFAULT_FONTS.sydney.morning.accent
      ? THEME_DEFAULT_FONTS.sydney.morning.accent.value
      : 'unset',
    body: THEME_DEFAULT_FONTS.sydney.morning.body.value,
    heading: THEME_DEFAULT_FONTS.sydney.morning.headline.value,
  },
});
