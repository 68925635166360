import { templateThemeTokens } from './templateThemeTokens.js';
import { THEME_DEFAULT_FONTS } from '@/contexts/CustomThemeConfigContext/constants.js';
import { createTheme } from '@/stitches/index.js';
import { merge } from 'lodash-es';

const themeObject = merge(templateThemeTokens, {
  colors: {
    defaultFont: '#000000',
    pageBackground: '#FAF6F2',
    secondaryFont: '#6A57E3',
    sectionBackground: '#FAF6F2',
  },
  fonts: {
    accent: THEME_DEFAULT_FONTS.barcelona.dusk.accent
      ? THEME_DEFAULT_FONTS.barcelona.dusk.accent.value
      : 'unset',
    body: THEME_DEFAULT_FONTS.barcelona.dusk.body.value,
    heading: THEME_DEFAULT_FONTS.barcelona.dusk.headline.value,
  },
  fontWeights: {
    heading: '600',
  },
});

export const dusk = createTheme({ ...themeObject });
