import { fontStacks } from '@/stitches/fonts.js';
import { sharedThemeTokens } from '@/templates/sharedThemeTokens.js';
import { merge } from 'lodash-es';

export const templateThemeTokens = merge(sharedThemeTokens, {
  fonts: {
    body: fontStacks.workSans.value,
    heading: fontStacks.clashDisplay.value,
  },
});
