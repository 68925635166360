import { isSupportedThemeSlug } from './isSupportedThemeSlug.js';
import { type ThemeNames } from '@/templates/templateTypes.js';

export const determineValidTheme = (
  defaultTheme: ThemeNames,
  override?: string,
  userProfile?: string,
): ThemeNames => {
  if (override && isSupportedThemeSlug(override)) {
    return override;
  }

  if (userProfile && isSupportedThemeSlug(userProfile)) {
    return userProfile;
  }

  return defaultTheme;
};
