import { templateThemeTokens } from './templateThemeTokens.js';
import { THEME_DEFAULT_FONTS } from '@/contexts/CustomThemeConfigContext/constants.js';
import { createTheme } from '@/stitches/index.js';

export const midnight = createTheme({
  ...templateThemeTokens,
  colors: {
    cardGradient1: 'rgba(255, 255, 255, 0.21)',
    cardGradient2: 'rgba(255, 255, 255, 0.07)',
    defaultFont: '$white',
    inverseDefaultFont: '$brandBlack',
    pageBackground: '$brandBlack',
    secondaryFont: '#A9FD31',
    sectionBackground: '#171717',
  },
  fonts: {
    ...templateThemeTokens.fonts,
    accent: THEME_DEFAULT_FONTS.tokyo.midnight.accent
      ? THEME_DEFAULT_FONTS.tokyo.midnight.accent.value
      : 'unset',
    body: THEME_DEFAULT_FONTS.tokyo.midnight.body.value,
    heading: THEME_DEFAULT_FONTS.tokyo.midnight.headline.value,
  },
  shadows: {
    basic10: '0 0 $64 rgba(228, 232, 247, 0.15)',
  },
});
