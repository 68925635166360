import { templateThemeTokens } from './templateThemeTokens.js';
import { THEME_DEFAULT_FONTS } from '@/contexts/CustomThemeConfigContext/constants.js';
import { createTheme } from '@/stitches/index.js';

export const morning = createTheme({
  ...templateThemeTokens,
  colors: {
    cardGradient1: 'rgba(255, 255, 255, 0.21)',
    cardGradient2: 'rgba(255, 255, 255, 0.07)',
    defaultFont: '$brandBlack',
    inverseDefaultFont: '$white',
    pageBackground: '$white',
    secondaryFont: 'rgba(145, 152, 189, 0.28)',
    sectionBackground: 'rgba(234, 234, 234, 0.3)',
  },
  fonts: {
    ...templateThemeTokens.fonts,
    accent: THEME_DEFAULT_FONTS.tokyo.morning.accent
      ? THEME_DEFAULT_FONTS.tokyo.morning.accent.value
      : 'unset',
    body: THEME_DEFAULT_FONTS.tokyo.morning.body.value,
    heading: THEME_DEFAULT_FONTS.tokyo.morning.headline.value,
  },
});
