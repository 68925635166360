import gradientBlobs from '@/components/Canvas/gradientBlobs.glsl';
import {
  type CanvasConfig,
  type CanvasOptions,
} from '@/components/Canvas/types.js';

const TOKYO_DAWN: CanvasConfig = {
  alpha: 1,
  backgroundColor: '#EFEAFC',
  blendMode: 'multiply',
  blobA: {
    blur: 0.9,
    color: '#E3BBFF',
    mouseIntensity: 0.05,
    oscillatePosition: true,
    position: { x: 0.8, y: 1 },
    radius: 1,
  },
  blobB: {
    blur: 0.9,
    color: '#FAAE79',
    mouseIntensity: 0.05,
    oscillatePosition: true,
    position: { x: 0, y: -0.2 },
    radius: 0.6,
  },
  fragment: gradientBlobs,
  maskColor: '#FAAE79',
  noiseIntensity: 0.2,
  position: 'fixed',
};

export const canvasOptions: CanvasOptions = {
  dawn: {
    config: {
      desktop: TOKYO_DAWN,
      mobile: {
        ...TOKYO_DAWN,
        blobA: {
          blur: 5,
          position: { x: 0.7, y: 1 },
          ...TOKYO_DAWN['blobA'],
        },
        blobB: {
          ...TOKYO_DAWN['blobB'],
          blur: 5,
          position: { x: 0.2, y: 0.6 },
        },
      },
    },
    enable: true,
  },
  dusk: {
    config: null,
    enable: false,
  },
  midday: {
    config: null,
    enable: false,
  },
  midnight: {
    config: null,
    enable: false,
  },
  morning: {
    config: null,
    enable: false,
  },
};
