import { type ImageSet, type PreviewConfig } from './types.js';

/**
 * The order matters in each set array, i.e., they're ordered by preference. So if we're displaying a template that only uses, say, 2 images but the actual set contains 4, we always pick the first 2 ones and do not pick 2 out of 4 randomly.
 */
export const PROJECT_COVERS_UIDS: Record<ImageSet, string[]> = Object.freeze({
  set_01: [
    'laeljq5gc5k98pfxq6ho',
    'nkabdhdyvd69ciujilrn',
    'qsbacndceyxdqxy8r3sl',
    't2ej6xkfbi4mc9q6ftbn',
  ],
  set_02: [
    'cn83cubcrnb7z0dpfn3i',
    'uol3cewz3f2qrjprm6oc',
    'snz2clf0cfgrc0rli05a',
    'vw2esxqyakbjdsitesin',
  ],
  set_03: [
    'sxvikl1ik8jvjgtrxi5q',
    'u1gfuk4uu7rqstzbctjz',
    'ng1ac4nfpgrpkeppzexr',
  ],
  set_04: [
    'womqe8lmis6bmcaemnmk',
    'f5gah4dvlka29ndydh3b',
    'snz2clf0cfgrc0rli05a',
    'hu5vnzyuixuggm7guruy',
  ],
});

export const DEFAULT_PREVIEW_CONFIG: PreviewConfig = {
  images: {
    set: {
      dawn: 'set_01',
      dusk: 'set_01',
      midday: 'set_01',
      midnight: 'set_01',
      morning: 'set_01',
    },
    useHighRes: false,
  },
  projects: {
    quantity: 2,
  },
  services: {
    quantity: 2,
  },
};
