import gradientBlobs from '@/components/Canvas/gradientBlobs.glsl';
import {
  type CanvasConfig,
  type CanvasOptions,
} from '@/components/Canvas/types.js';

const LOS_ANGELES_DAWN: CanvasConfig = {
  alpha: 1,
  backgroundColor: '#FFFFFF',
  blendMode: null,
  blobA: {
    blur: 2,
    color: '#9999ff',
    mouseIntensity: 0.1,
    oscillatePosition: false,
    position: { x: -0.1, y: 0.5 },
    radius: 2,
  },
  blobB: {
    blur: 2,
    color: '#A3E6DA',
    mouseIntensity: 0.2,
    oscillatePosition: true,
    position: { x: 0.75, y: 0.5 },
    radius: 0.9,
  },
  fragment: gradientBlobs,
  noiseIntensity: 0.4,
  position: 'fixed',
};

export const canvasOptions: CanvasOptions = {
  dawn: {
    config: {
      desktop: LOS_ANGELES_DAWN,
      mobile: {
        ...LOS_ANGELES_DAWN,
        blobA: {
          ...LOS_ANGELES_DAWN['blobA'],
          position: { x: -0.2, y: 0.2 },
        },
        blobB: {
          ...LOS_ANGELES_DAWN['blobB'],
          position: { x: 0.9, y: 1 },
        },
      },
    },
    enable: true,
  },
  dusk: {
    config: null,
    enable: false,
  },
  midday: {
    config: null,
    enable: false,
  },
  midnight: {
    config: null,
    enable: false,
  },
  morning: {
    config: null,
    enable: false,
  },
};
