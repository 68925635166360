import { templateThemeTokens } from './templateThemeTokens.js';
import { fontStacks } from '@/stitches/fonts.js';
import { createTheme } from '@/stitches/index.js';
import { merge } from 'lodash-es';

const mergedTokens = merge(templateThemeTokens, {
  colors: {
    defaultFont: '#383531',
    pageBackground: '#F5EBD4',
  },
  fonts: {
    body: fontStacks.clashDisplay.value,
    heading: fontStacks.clashDisplay.value,
  },
});

export const morning = createTheme({ ...mergedTokens });
