import { templateThemeTokens } from './templateThemeTokens.js';
import { fontStacks } from '@/stitches/fonts.js';
import { createTheme } from '@/stitches/index.js';
import { merge } from 'lodash-es';

const mergedTokens = merge(templateThemeTokens, {
  colors: {
    defaultFont: '$brandBlack',
    pageBackground: '$white',
    secondaryFont: '#9BA2B0',
    sectionBackground: '#F9FAFB',
  },
  fonts: {
    body: fontStacks.satoshi.value,
    heading: fontStacks.satoshi.value,
  },
  fontWeights: {
    body: 400,
    heading: 400,
  },
});

export const morning = createTheme({ ...mergedTokens });
