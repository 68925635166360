import { templateThemeTokens } from './templateThemeTokens.js';
import { THEME_DEFAULT_FONTS } from '@/contexts/CustomThemeConfigContext/constants.js';
import { createTheme } from '@/stitches/index.js';
import { merge } from 'lodash-es';

const themeObject = merge(templateThemeTokens, {
  colors: {
    blobGradient1: '#FFBD7F',
    blobGradient2: '#F2C94C',
    blobGradient3: '#FFBD7F',
    defaultFont: '$brandBlack',
    footerGradient1: '#CBACD4',
    footerGradient2: '#CBACD4',
    gray1: '#E4E7E1',
    green1: '#A9FD31',
    green2: '#333F2D',
    hero1: '#CBACD4',
    hero2: '#CBACD4',
    hero3: '#CBACD4',
    highlight: '$brandBlack',
    inverseDefaultFont: '$gray1',
    navbarFont: '$brandBlack',
    pageBackground: '#CBACD4',
    secondaryButtonFont: '$white',
    secondaryFont: '$white',
  },
  fonts: {
    accent: THEME_DEFAULT_FONTS.barcelona.dawn.accent
      ? THEME_DEFAULT_FONTS.barcelona.dawn.accent.value
      : 'unset',
    body: THEME_DEFAULT_FONTS.barcelona.dawn.body.value,
    heading: THEME_DEFAULT_FONTS.barcelona.dawn.headline.value,
  },
  fontWeights: {
    heading: '600',
  },
});

export const dawn = createTheme({ ...themeObject });
