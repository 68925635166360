import { templateThemeTokens } from './templateThemeTokens.js';
import { fontStacks } from '@/stitches/fonts.js';
import { createTheme } from '@/stitches/index.js';
import { merge } from 'lodash-es';

const mergedTokens = merge(templateThemeTokens, {
  colors: {
    defaultFont: '$white',
    pageBackground: '#525634',
    secondaryFont: '#F1FF99',
    sectionBackground: '#5C623A',
  },
  fonts: {
    body: fontStacks.satoshi.value,
    heading: fontStacks.bricolage.value,
  },
  fontWeights: {
    body: 400,
    heading: 400,
  },
});

export const dusk = createTheme({ ...mergedTokens });
