import { templateThemeTokens } from './templateThemeTokens.js';
import { fontStacks } from '@/stitches/fonts.js';
import { createTheme } from '@/stitches/index.js';
import { merge } from 'lodash-es';

const mergedTokens = merge(templateThemeTokens, {
  colors: {
    defaultFont: '#2C2F12',
    pageBackground: '#CFD7F0',
    secondaryFont: '#EBFF67',
    sectionBackground: 'rgba(255, 255, 255, 0.2)',
  },
  fonts: {
    accent: fontStacks.satoshi.value,
    body: fontStacks.archivo.value,
    heading: fontStacks.archivo.value,
  },
  fontWeights: {
    body: 300,
    heading: 500,
  },
});

export const midday = createTheme({ ...mergedTokens });
