import { templateThemeTokens } from './templateThemeTokens.js';
import { THEME_DEFAULT_FONTS } from '@/contexts/CustomThemeConfigContext/constants.js';
import { createTheme } from '@/stitches/index.js';
import { merge } from 'lodash-es';

const themeObject = merge(templateThemeTokens, {
  colors: {
    defaultFont: '#333F2D',
    pageBackground: '#F1C8F9',
    secondaryFont: '#E98034',
    sectionBackground: '#FFFBEF',
  },
  fonts: {
    accent: THEME_DEFAULT_FONTS.barcelona.midday.accent
      ? THEME_DEFAULT_FONTS.barcelona.midday.accent.value
      : 'unset',
    body: THEME_DEFAULT_FONTS.barcelona.midday.body.value,
    heading: THEME_DEFAULT_FONTS.barcelona.midday.headline.value,
  },
  fontWeights: {
    heading: '400',
  },
});

export const midday = createTheme({ ...themeObject });
