import { isSupportedTemplateSlug } from './isSupportedTemplateSlug.js';
import { type TemplateNames } from '@/templates/templateTypes.js';

export const determineValidTemplate = (
  defaultTemplate: TemplateNames,
  override?: string,
  userProfile?: string,
): TemplateNames => {
  if (override && isSupportedTemplateSlug(override)) return override;
  if (userProfile && isSupportedTemplateSlug(userProfile)) return userProfile;
  return defaultTemplate;
};
