import { fontStacks } from '@/stitches/fonts.js';
import { sharedThemeTokens } from '@/templates/sharedThemeTokens.js';
import { pxToRem } from '@/utilities/pxToRem.js';

export const templateThemeTokens = {
  ...sharedThemeTokens,
  fonts: {
    body: fontStacks.workSans.value,
    heading: fontStacks.clashDisplay.value,
  },
  lineHeights: {
    body: '1.4',
  },
  shadows: {
    button: `${pxToRem(4)} ${pxToRem(4)} ${pxToRem(8)} rgba(0, 0, 0, 0.1)`,
  },
};
