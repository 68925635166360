import { type PreviewConfig } from '../types.js';

export const previewConfig: PreviewConfig = {
  images: {
    set: {
      dawn: 'set_01',
      dusk: 'set_01',
      midday: 'set_01',
      midnight: 'set_01',
      morning: 'set_01',
    },
    useHighRes: true,
  },
  projects: {
    quantity: 3,
  },
  services: {
    quantity: 3,
  },
};
