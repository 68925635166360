import { TemplateAndThemeContext } from '@/contexts/TemplateAndThemeContext/TemplateAndThemeContext.js';
import { type TemplateAndThemeContextType } from '@/contexts/TemplateAndThemeContext/types.js';
import { useContext } from 'react';

export const useTemplate = () => {
  const context = useContext<TemplateAndThemeContextType>(
    TemplateAndThemeContext,
  );
  return {
    ...context.template,
    isContextLoaded: context.isContextLoaded,
  };
};
