import gradientBlobs3 from '@/components/Canvas/gradientBlobs3.glsl';
import {
  type CanvasConfig,
  type CanvasOptions,
} from '@/components/Canvas/types.js';

const BARCELONA_DAWN: CanvasConfig = {
  alpha: 1,
  backgroundColor: '#ccabd4',
  blendMode: 'multiply',
  blobA: {
    blur: 2,
    color: '#f0b18d',
    mouseIntensity: 0.1,
    position: { x: 0, y: 1 },
    radius: 1,
  },
  blobB: {
    blur: 2,
    color: '#F89255',
    mouseIntensity: 0.1,
    position: { x: 1.2, y: 1 },
    radius: 1,
  },
  blobC: {
    blur: 2,
    color: '#f0b18d',
    mouseIntensity: -0.3,
    position: { x: 0.2, y: 0 },
    radius: 0.4,
  },
  fragment: gradientBlobs3,
  maskColor: '#ffffff',
  noiseIntensity: 0.25,
  position: 'fixed',
};

export const canvasOptions: CanvasOptions = {
  dawn: {
    config: {
      desktop: BARCELONA_DAWN,
      mobile: {
        ...BARCELONA_DAWN,
        backgroundColor: '#CBACD4',
        blobA: {
          ...BARCELONA_DAWN['blobA'],
          radius: 0,
        },
        blobB: {
          ...BARCELONA_DAWN['blobB'],
          radius: 0,
        },
        blobC: {
          ...BARCELONA_DAWN['blobC'],
          blur: 4,
          mouseIntensity: 0,
          noiseIntensity: 0.1,
          position: { x: 0.5, y: 0.9 },
          radius: 6,
        },
        position: 'fixed',
      },
    },
    enable: true,
  },
  dusk: {
    config: null,
    enable: false,
  },
  midday: {
    config: null,
    enable: false,
  },
  midnight: {
    config: null,
    enable: false,
  },
  morning: {
    config: null,
    enable: false,
  },
};
