import { templateThemeTokens } from './templateThemeTokens.js';
import { THEME_DEFAULT_FONTS } from '@/contexts/CustomThemeConfigContext/constants.js';
import { createTheme } from '@/stitches/index.js';
import { merge } from 'lodash-es';

const mergedTokens = merge(templateThemeTokens, {
  colors: {
    defaultFont: '$white',
    inverseDefaultFont: '$brandBlack',
    pageBackground: '$brandBlack',
  },
  fonts: {
    accent: THEME_DEFAULT_FONTS.madrid.midnight.accent
      ? THEME_DEFAULT_FONTS.madrid.midnight.accent.value
      : 'unset',
    body: THEME_DEFAULT_FONTS.madrid.midnight.body.value,
    heading: THEME_DEFAULT_FONTS.madrid.midnight.headline.value,
  },
});

export const midnight = createTheme({ ...mergedTokens });
