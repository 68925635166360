import { templateThemeTokens } from './templateThemeTokens.js';
import { fontStacks } from '@/stitches/fonts.js';
import { createTheme } from '@/stitches/index.js';
import { merge } from 'lodash-es';

const mergedTokens = merge(templateThemeTokens, {
  colors: {
    defaultFont: '$white',
    pageBackground: '#191919',
    secondaryFont: '$brandPurple',
    sectionBackground: '#000000',
  },
  fonts: {
    accent: fontStacks.satoshi.value,
    body: fontStacks.cabinet.value,
    heading: fontStacks.cabinet.value,
  },
  fontWeights: {
    body: 400,
    heading: 400,
  },
});

export const midnight = createTheme({ ...mergedTokens });
