import {
  type CustomThemeConfigType,
  type CustomThemeDefaultFonts,
  type ThemeConfigTokenMap,
} from './types.js';
import { fontStacks } from '@/stitches/fonts.js';
import { theme } from '@/stitches/index.js';

// TODO: This should come from generation.
// Query the API and get a list of all background styles NIDs.
export const ALL_BACKGROUND_STYLE_NIDS = [
  'BARCELONA_A',
  'DEFAULT',
  'GRADIENT_A',
  'GRADIENT_B',
  'GRADIENT_C',
  'LOS_ANGELES_B',
  'NONE',
  'TOKYO_A',
  'TOKYO_B',
  'TOKYO_C',
] as const;

// TODO: This should come from generation.
// Query the API and get a list of all background styles and their API IDs.
export const ALL_BACKGROUND_STYLE_IDS: Record<
  (typeof ALL_BACKGROUND_STYLE_NIDS)[number],
  string
> = {
  BARCELONA_A: 'WyJUaGVtZUJhY2tncm91bmRTdHlsZSIsMTBd',
  DEFAULT: 'WyJUaGVtZUJhY2tncm91bmRTdHlsZSIsMjRd',
  GRADIENT_A: 'WyJUaGVtZUJhY2tncm91bmRTdHlsZSIsMl0=',
  GRADIENT_B: 'WyJUaGVtZUJhY2tncm91bmRTdHlsZSIsM10=',
  GRADIENT_C: 'WyJUaGVtZUJhY2tncm91bmRTdHlsZSIsNF0=',
  LOS_ANGELES_B: 'WyJUaGVtZUJhY2tncm91bmRTdHlsZSIsMjFd',
  NONE: 'WyJUaGVtZUJhY2tncm91bmRTdHlsZSIsNV0=',
  TOKYO_A: 'WyJUaGVtZUJhY2tncm91bmRTdHlsZSIsMTRd',
  TOKYO_B: 'WyJUaGVtZUJhY2tncm91bmRTdHlsZSIsMTVd',
  TOKYO_C: 'WyJUaGVtZUJhY2tncm91bmRTdHlsZSIsMTZd',
};

// TODO: These should come from generation.
export const BACKGROUND_STYLE_DEFAULT_ID =
  'WyJUaGVtZUJhY2tncm91bmRTdHlsZSIsMjRd';
const PROJECT_LAYOUT_NONE_ID = 'WyJUaGVtZVByb2plY3RMYXlvdXQiLDld';
const SERVICE_LAYOUT_NONE_ID = 'WyJUaGVtZVNlcnZpY2VMYXlvdXQiLDVd';
export const FONT_PACK_NONE_ID = 'WyJUaGVtZUZvbnRQYWNrIiwyOV0=';

// ----------------------------------------------------------

export const THEME_CONFIG_TOKEN_MAP: ThemeConfigTokenMap = {
  backgroundColor: theme.colors.pageBackground.variable,
  fontColorPrimary: theme.colors.defaultFont.variable,
  fontColorSecondary: theme.colors.secondaryFont.variable,
  fontFamilyAccent: theme.fonts.accent.variable,
  fontFamilyBody: theme.fonts.body.variable,
  fontFamilyHeading: theme.fonts.heading.variable,
};

export const DEFAULT_THEME_CONFIG: CustomThemeConfigType = {
  accentFontId: FONT_PACK_NONE_ID,
  backgroundStyleId: BACKGROUND_STYLE_DEFAULT_ID,
  bodyFontId: FONT_PACK_NONE_ID,
  headingFontId: FONT_PACK_NONE_ID,
  logoImage: null,
  projectsVariantId: PROJECT_LAYOUT_NONE_ID,
  servicesVariantId: SERVICE_LAYOUT_NONE_ID,
};

export const THEME_DEFAULT_FONTS: CustomThemeDefaultFonts = {
  amsterdam: {
    dawn: {
      accent: fontStacks['cabinet'],
      body: fontStacks['epilogue'],
      headline: fontStacks['epilogue'],
    },
    dusk: {
      accent: fontStacks['cabinet'],
      body: fontStacks['generalSans'],
      headline: fontStacks['generalSans'],
    },
    midday: {
      accent: fontStacks['cabinet'],
      body: fontStacks['switzer'],
      headline: fontStacks['switzer'],
    },
    midnight: {
      accent: fontStacks['cabinet'],
      body: fontStacks['switzer'],
      headline: fontStacks['switzer'],
    },
    morning: {
      accent: fontStacks['cabinet'],
      body: fontStacks['cabinet'],
      headline: fontStacks['cabinet'],
    },
  },
  barcelona: {
    dawn: {
      accent: fontStacks['panchang'],
      body: fontStacks['cabinet'],
      headline: fontStacks['cabinet'],
    },
    dusk: {
      accent: fontStacks['panchang'],
      body: fontStacks['cabinet'],
      headline: fontStacks['cabinet'],
    },
    midday: {
      accent: fontStacks['cabinet'],
      body: fontStacks['cabinet'],
      headline: fontStacks['cabinet'],
    },
    midnight: {
      accent: fontStacks['cabinet'],
      body: fontStacks['cabinet'],
      headline: fontStacks['cabinet'],
    },
    morning: {
      accent: fontStacks['cabinet'],
      body: fontStacks['cabinet'],
      headline: fontStacks['cabinet'],
    },
  },
  capeTown: {
    dawn: {
      body: fontStacks['generalSans'],
      headline: fontStacks['generalSans'],
    },
    dusk: {
      body: fontStacks['satoshi'],
      headline: fontStacks['bricolage'],
    },
    midday: {
      body: fontStacks['archivo'],
      headline: fontStacks['archivo'],
    },
    midnight: {
      body: fontStacks['cabinet'],
      headline: fontStacks['cabinet'],
    },
    morning: {
      body: fontStacks['satoshi'],
      headline: fontStacks['satoshi'],
    },
  },
  london: {
    dawn: {
      accent: undefined,
      body: fontStacks['switzer'],
      headline: fontStacks['butler'],
    },
    dusk: {
      accent: undefined,
      body: fontStacks['satoshi'],
      headline: fontStacks['zodiak'],
    },
    midday: {
      accent: undefined,
      body: fontStacks['cabinet'],
      headline: fontStacks['cabinet'],
    },
    midnight: {
      accent: undefined,
      body: fontStacks['cabinet'],
      headline: fontStacks['cabinet'],
    },
    morning: {
      accent: undefined,
      body: fontStacks['switzer'],
      headline: fontStacks['butler'],
    },
  },
  losAngeles: {
    dawn: {
      accent: undefined,
      body: fontStacks['cabinet'],
      headline: fontStacks['cabinet'],
    },
    dusk: {
      accent: undefined,
      body: fontStacks['cabinet'],
      headline: fontStacks['panchang'],
    },
    midday: {
      accent: undefined,
      body: fontStacks['cabinet'],
      headline: fontStacks['gambarino'],
    },
    midnight: {
      accent: undefined,
      body: fontStacks['cabinet'],
      headline: fontStacks['cabinet'],
    },
    morning: {
      accent: undefined,
      body: fontStacks['cabinet'],
      headline: fontStacks['gambarino'],
    },
  },
  madrid: {
    dawn: {
      accent: undefined,
      body: fontStacks['epilogue'],
      headline: fontStacks['neco'],
    },
    dusk: {
      accent: undefined,
      body: fontStacks['epilogue'],
      headline: fontStacks['neco'],
    },
    midday: {
      accent: undefined,
      body: fontStacks['epilogue'],
      headline: fontStacks['neco'],
    },
    midnight: {
      accent: undefined,
      body: fontStacks['switzer'],
      headline: fontStacks['switzer'],
    },
    morning: {
      accent: undefined,
      body: fontStacks['cabinet'],
      headline: fontStacks['cabinet'],
    },
  },
  paris: {
    dawn: {
      accent: undefined,
      body: fontStacks['workSans'],
      headline: fontStacks['generalSans'],
    },
    dusk: {
      accent: undefined,
      body: fontStacks['workSans'],
      headline: fontStacks['clashDisplay'],
    },
    midday: {
      accent: undefined,
      body: fontStacks['workSans'],
      headline: fontStacks['butler'],
    },
    midnight: {
      accent: undefined,
      body: fontStacks['workSans'],
      headline: fontStacks['panchang'],
    },
    morning: {
      accent: undefined,
      body: fontStacks['workSans'],
      headline: fontStacks['butler'],
    },
  },
  saoPaulo: {
    dawn: {
      accent: fontStacks['cabinet'],
      body: fontStacks['generalSans'],
      headline: fontStacks['lora'],
    },
    dusk: {
      accent: fontStacks['cabinet'],
      body: fontStacks['satoshi'],
      headline: fontStacks['satoshi'],
    },
    midday: {
      accent: fontStacks['cabinet'],
      body: fontStacks['clashDisplay'],
      headline: fontStacks['clashDisplay'],
    },
    midnight: {
      accent: fontStacks['cabinet'],
      body: fontStacks['switzer'],
      headline: fontStacks['neco'],
    },
    morning: {
      accent: fontStacks['cabinet'],
      body: fontStacks['switzer'],
      headline: fontStacks['butler'],
    },
  },
  sydney: {
    dawn: {
      accent: undefined,
      body: fontStacks['switzer'],
      headline: fontStacks['switzer'],
    },
    dusk: {
      accent: undefined,
      body: fontStacks['generalSans'],
      headline: fontStacks['generalSans'],
    },
    midday: {
      accent: undefined,
      body: fontStacks['spaceGrotesk'],
      headline: fontStacks['syne'],
    },
    midnight: {
      accent: undefined,
      body: fontStacks['switzer'],
      headline: fontStacks['switzer'],
    },
    morning: {
      accent: undefined,
      body: fontStacks['switzer'],
      headline: fontStacks['zodiak'],
    },
  },
  tokyo: {
    dawn: {
      accent: fontStacks['cabinet'],
      body: fontStacks['satoshi'],
      headline: fontStacks['gambarino'],
    },
    dusk: {
      accent: fontStacks['clashDisplay'],
      body: fontStacks['satoshi'],
      headline: fontStacks['cabinet'],
    },
    midday: {
      accent: fontStacks['panchang'],
      body: fontStacks['satoshi'],
      headline: fontStacks['cabinet'],
    },
    midnight: {
      accent: fontStacks['panchang'],
      body: fontStacks['satoshi'],
      headline: fontStacks['satoshi'],
    },
    morning: {
      accent: fontStacks['anton'],
      body: fontStacks['satoshi'],
      headline: fontStacks['gambarino'],
    },
  },
};

// TODO:
// Add `satisfies Partial<Record<TemplateNames, readonly ThemeNames[]>>` to the end of this
// once Next.js supports the operator.
export const THEMES_WITH_SECONDARY_FONT_COLOR: Record<
  string,
  readonly string[]
> = {
  barcelona: ['dawn', 'morning', 'midday', 'dusk', 'midnight'],
  losAngeles: ['dusk'],
  madrid: ['dawn', 'morning'],
  paris: ['dawn', 'morning', 'midday', 'dusk', 'midnight'],
  sydney: ['dusk', 'midnight'],
  tokyo: ['midday', 'midnight'],
};
