import { templateThemeTokens } from './templateThemeTokens.js';
import { fontStacks } from '@/stitches/fonts.js';
import { createTheme } from '@/stitches/index.js';
import { merge } from 'lodash-es';

const mergedTokens = merge(templateThemeTokens, {
  colors: {
    defaultFont: '#312D28',
    pageBackground: '#F1ECE5',
    secondaryFont: '#82662E',
    sectionBackground: 'rgba(255, 255, 255, 0.3)',
  },
  fonts: {
    body: fontStacks.generalSans.value,
    heading: fontStacks.gilda.value,
  },
  fontWeights: {
    body: 400,
    heading: 400,
  },
  lineHeights: {
    body: 1.4,
    heading: 1,
  },
});

export const dawn = createTheme({ ...mergedTokens });
