import { templateThemeTokens } from './templateThemeTokens.js';
import { THEME_DEFAULT_FONTS } from '@/contexts/CustomThemeConfigContext/constants.js';
import { createTheme } from '@/stitches/index.js';

export const dusk = createTheme({
  ...templateThemeTokens,
  colors: {
    defaultFont: '#14171F',
    pageBackground: '#DDD8FF',
    secondaryFont: '#6A57E3',
  },
  fonts: {
    accent: THEME_DEFAULT_FONTS.losAngeles.dusk.accent
      ? THEME_DEFAULT_FONTS.losAngeles.dusk.accent.value
      : 'unset',
    body: THEME_DEFAULT_FONTS.losAngeles.dusk.body.value,
    heading: THEME_DEFAULT_FONTS.losAngeles.dusk.headline.value,
  },
  shadows: {
    basic10: '0 0 $64 rgba(228, 232, 247, 0.15)',
  },
});
