import { templateThemeTokens } from './templateThemeTokens.js';
import { THEME_DEFAULT_FONTS } from '@/contexts/CustomThemeConfigContext/constants.js';
import { createTheme } from '@/stitches/index.js';

export const midnight = createTheme({
  ...templateThemeTokens,
  colors: {
    defaultFont: '#FFFFFF',
    pageBackground: '#222C41',
  },
  fonts: {
    accent: THEME_DEFAULT_FONTS.losAngeles.midnight.accent
      ? THEME_DEFAULT_FONTS.losAngeles.midnight.accent.value
      : 'unset',
    body: THEME_DEFAULT_FONTS.losAngeles.midnight.body.value,
    heading: THEME_DEFAULT_FONTS.losAngeles.midnight.headline.value,
  },
  shadows: {
    basic10: '0 0 $64 rgba(228, 232, 247, 0.15)',
  },
});
