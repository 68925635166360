import { type TemplateAndThemeContextType } from './types.js';
import { buildTemplates } from './utils/buildTemplates.js';
import { determineValidTemplate } from './utils/determineValidTemplate.js';
import { determineValidTheme } from './utils/determineValidTheme.js';
import { getStyleOverride } from './utils/getStyleOverride.js';
import { type IndependentPortfolioTemplates_queryFragment$key } from '@/__generated__/relay/IndependentPortfolioTemplates_queryFragment.graphql.js';
import { independentPortfolioTemplatesFragment } from '@/documents/fragments/IndependentPortfolioTemplates.js';
import { useUserProfile } from '@/hooks/useUserProfile.js';
import { DEFAULT_PREVIEW_CONFIG } from '@/templates/globalPreviewConfig.js';
import { previewConfigRegistry } from '@/templates/previewConfigRegistry.js';
import {
  AllTemplateNames,
  AllThemeNames,
  type TemplateNames,
  type ThemeNames,
} from '@/templates/templateTypes.js';
import { themeRegistry } from '@/templates/themeRegistry.js';
import { useRouter } from 'next/router';
import {
  createContext,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
// eslint-disable-next-line canonical/no-restricted-imports
import { useFragment } from 'react-relay';

const defaultProviderValue = {
  hasLiveTemplate: false,
  isContextLoaded: false,
  template: {
    change: () => {},
    current: AllTemplateNames[0],
    currentTemplateId: null,
    previewConfig: DEFAULT_PREVIEW_CONFIG,
  },
  templateById: () => undefined,
  templateBySlug: () => undefined,
  templates: [],
  theme: {
    change: () => {},
    current: AllThemeNames[0],
    currentThemeId: null,
    themeObject: null,
  },
};

export const TemplateAndThemeContext =
  createContext<TemplateAndThemeContextType>(defaultProviderValue);

export const TemplateAndThemeContextProvider = ({
  children,
  queryRef,
  templateThemeOverride,
}: {
  readonly children: ReactNode;
  readonly queryRef: IndependentPortfolioTemplates_queryFragment$key;
  readonly templateThemeOverride?: string;
}) => {
  const router = useRouter();

  // styleOverride - template:theme
  const styleOverrideParameter = router?.query['styleOverride'] ?? '';
  const userProfile = useUserProfile();

  const data = useFragment<IndependentPortfolioTemplates_queryFragment$key>(
    independentPortfolioTemplatesFragment,
    queryRef,
  );

  const templates = buildTemplates(
    data?.independentPortfolioTemplates?.edges ?? [],
  );

  const defaultTemplate = 'sydney';
  const defaultTheme = 'dawn';

  const userSelectedTemplate =
    userProfile?.independentPortfolioTemplateTheme?.template?.developmentSlug;
  const userSelectedTheme =
    userProfile?.independentPortfolioTemplateTheme?.developmentSlug;

  const [overrideTemplate, overrideTheme] = getStyleOverride(
    templateThemeOverride ?? styleOverrideParameter,
  );

  const [currentTemplate, setCurrentTemplate] = useState(
    determineValidTemplate(
      defaultTemplate,
      overrideTemplate,
      userSelectedTemplate,
    ),
  );

  const [currentTheme, setCurrentTheme] = useState<ThemeNames>(
    determineValidTheme(defaultTheme, overrideTheme, userSelectedTheme),
  );

  const changeTheme = useCallback((themeName: ThemeNames) => {
    setCurrentTheme(themeName);
  }, []);

  const changeTemplate = useCallback((templateName: TemplateNames) => {
    setCurrentTemplate(templateName);
  }, []);

  const templateById = useCallback(
    (id: string) => templates.find(({ id: fieldToTest }) => id === fieldToTest),
    [templates],
  );

  const templateBySlug = useCallback(
    (developmentSlug: TemplateNames) =>
      templates.find(
        ({ developmentSlug: fieldToTest }) => developmentSlug === fieldToTest,
      ),
    [templates],
  );

  // Manage updating template/theme on the fly if the override changes
  useEffect(() => {
    if (templateThemeOverride) {
      const [newOverrideTemplate, newOverrideTheme] = getStyleOverride(
        templateThemeOverride,
      );

      const overridesAreDefined = newOverrideTheme && newOverrideTemplate;
      const haveChanged =
        newOverrideTemplate !== currentTemplate ||
        newOverrideTheme !== currentTheme;

      if (overridesAreDefined && haveChanged) {
        changeTheme(newOverrideTheme);
        changeTemplate(newOverrideTemplate);
      }
    }
  }, [
    changeTemplate,
    changeTheme,
    currentTemplate,
    currentTheme,
    templateThemeOverride,
  ]);

  const currentThemeId = useMemo(() => {
    const currentTemplateObject = templateBySlug(currentTemplate);
    const themes = currentTemplateObject?.themes ?? [];
    const currentThemeObject = themes.find(
      ({ developmentSlug }) => developmentSlug === currentTheme,
    );

    return currentThemeObject?.id ?? null;
  }, [currentTemplate, currentTheme, templateBySlug]);

  const currentTemplateId = useMemo(() => {
    const currentTemplateObject = templateBySlug(currentTemplate);
    return currentTemplateObject?.id ?? null;
  }, [currentTemplate, templateBySlug]);

  const themeObject = themeRegistry[currentTemplate][currentTheme];
  const canvasOptions = themeRegistry[currentTemplate].canvasOptions;
  const previewConfig = previewConfigRegistry[currentTemplate];

  const providerValue = useMemo(
    () => ({
      isContextLoaded: true,
      template: {
        canvasOptions,
        change: changeTemplate,
        current: currentTemplate,
        currentTemplateId,
        previewConfig,
      },
      templateById,
      templateBySlug,
      templates,
      theme: {
        change: changeTheme,
        current: currentTheme,
        currentThemeId,
        themeObject,
      },
    }),
    [
      canvasOptions,
      changeTemplate,
      changeTheme,
      currentTemplate,
      currentTemplateId,
      currentTheme,
      currentThemeId,
      previewConfig,
      templateById,
      templateBySlug,
      templates,
      themeObject,
    ],
  );

  return (
    <TemplateAndThemeContext.Provider value={providerValue}>
      {children}
    </TemplateAndThemeContext.Provider>
  );
};
