import { fontStacks } from '@/stitches/fonts.js';
import { sharedThemeTokens } from '@/templates/sharedThemeTokens.js';
import { merge } from 'lodash-es';

export const templateThemeTokens = merge(sharedThemeTokens, {
  colors: {},
  fonts: {
    accent: fontStacks.cabinet.value,
    body: fontStacks.cabinet.value,
    heading: fontStacks.cabinet.value,
  },
  space: {
    themeButtonHeight: '$48',
    themeButtonPadding: '$12 $24',
  },
});
