/**
 * All Templates should have a "templateThemesToken" file to build off of.
 * All of these should build off of this global sharedThemeTokens file.
 *
 * If you add a "global theme token" when building a top-level component,
 * make sure a safe default is in here.
 */
export const sharedThemeTokens = {
  borderWidths: {
    navbarWWM: '$safe1px',
  },
  colors: {
    defaultFont: '$brandBlack',
    pageBackground: '$white',
  },
  fontWeights: {
    body: 400,
    heading: 500,
  },
  lineHeights: {
    body: 1.5,
    heading: 1,
  },
  space: {
    desktopLeftGutter: '$48',
    mobileLeftGutter: '0',
  },
  transitions: {
    basicDuration: '250ms',
    longDuration: '500ms',
    veryLongDuration: '750ms',
  },
};
