import { templateThemeTokens } from './templateThemeTokens.js';
import { fontStacks } from '@/stitches/fonts.js';
import { createTheme } from '@/stitches/index.js';
import { merge } from 'lodash-es';

const mergedTokens = merge(templateThemeTokens, {
  colors: {
    defaultFont: '#14171F',
    pageBackground: '#E1E3CB',
  },
  fonts: {
    body: fontStacks.switzer.value,
    heading: fontStacks.butler.value,
  },
});

export const dawn = createTheme({ ...mergedTokens });
