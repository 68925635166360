/**
 * @generated SignedSource<<1a6a91cabb68eb6c1fc53e8e73e5743c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type IndependentPortfolioTemplateAccessLevel = "FREE" | "PRO" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type IndependentPortfolioTemplates_queryFragment$data = {
  readonly independentPortfolioTemplates: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accessLevel: IndependentPortfolioTemplateAccessLevel;
        readonly developmentSlug: string;
        readonly displayName: string;
        readonly id: string;
        readonly isLive: boolean;
        readonly themes: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly developmentSlug: string;
              readonly displayName: string;
              readonly generatedPreviewImage: string | null;
              readonly id: string;
              readonly isLive: boolean;
              readonly previewImage: {
                readonly id: string;
                readonly uid: string;
              };
            };
          }>;
        };
      };
    }>;
  };
  readonly " $fragmentType": "IndependentPortfolioTemplates_queryFragment";
};
export type IndependentPortfolioTemplates_queryFragment$key = {
  readonly " $data"?: IndependentPortfolioTemplates_queryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"IndependentPortfolioTemplates_queryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "developmentSlug",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLive",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IndependentPortfolioTemplates_queryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "IndependentPortfolioTemplateConnection",
      "kind": "LinkedField",
      "name": "independentPortfolioTemplates",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "IndependentPortfolioTemplateEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "IndependentPortfolioTemplate",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "accessLevel",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "IndependentPortfolioTemplateThemeConnection",
                  "kind": "LinkedField",
                  "name": "themes",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "IndependentPortfolioTemplateThemeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "IndependentPortfolioTemplateTheme",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            (v2/*: any*/),
                            (v3/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "generatedPreviewImage",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ContraImage",
                              "kind": "LinkedField",
                              "name": "previewImage",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "uid",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "530d06f78b5b3afd6708b35cc3da9015";

export default node;
