import * as amsterdamThemes from './amsterdam/themes/index.js';
import * as barcelonaThemes from './barcelona/themes/index.js';
import * as capeTownThemes from './capeTown/themes/index.js';
import * as londonThemes from './london/themes/index.js';
import * as losAngelesThemes from './losAngeles/themes/index.js';
import * as madridThemes from './madrid/themes/index.js';
import * as parisThemes from './paris/themes/index.js';
import * as saoPauloThemes from './saoPaulo/themes/index.js';
import * as sydneyThemes from './sydney/themes/index.js';
import * as tokyoThemes from './tokyo/themes/index.js';
import { type ThemeRegistry } from './types.js';

export const themeRegistry: ThemeRegistry = {
  amsterdam: amsterdamThemes,
  barcelona: barcelonaThemes,
  capeTown: capeTownThemes,
  london: londonThemes,
  losAngeles: losAngelesThemes,
  madrid: madridThemes,
  paris: parisThemes,
  saoPaulo: saoPauloThemes,
  sydney: sydneyThemes,
  tokyo: tokyoThemes,
};
