import { fontStacks } from '@/stitches/fonts.js';
import { sharedThemeTokens } from '@/templates/sharedThemeTokens.js';

export const templateThemeTokens = {
  ...sharedThemeTokens,
  fonts: {
    body: fontStacks.workSans.value,
    heading: fontStacks.gambarino.value,
  },
};
