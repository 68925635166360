import { previewConfig as amsterdamPreviewConfig } from './amsterdam/previewConfig.js';
import { previewConfig as barcelonaPreviewConfig } from './barcelona/previewConfig.js';
import { previewConfig as capetownPreviewConfig } from './capeTown/previewConfig.js';
import { previewConfig as londonPreviewConfig } from './london/previewConfig.js';
import { previewConfig as losAngelesPreviewConfig } from './losAngeles/previewConfig.js';
import { previewConfig as madridPreviewConfig } from './madrid/previewConfig.js';
import { previewConfig as parisPreviewConfig } from './paris/previewConfig.js';
import { previewConfig as saoPauloPreviewConfig } from './saoPaulo/previewConfig.js';
import { previewConfig as sydneyPreviewConfig } from './sydney/previewConfig.js';
import { type TemplateNames } from './templateTypes.js';
import { previewConfig as tokyoPreviewConfig } from './tokyo/previewConfig.js';
import { type PreviewConfig } from './types.js';

export const previewConfigRegistry: Record<TemplateNames, PreviewConfig> = {
  amsterdam: amsterdamPreviewConfig,
  barcelona: barcelonaPreviewConfig,
  capeTown: capetownPreviewConfig,
  london: londonPreviewConfig,
  losAngeles: losAngelesPreviewConfig,
  madrid: madridPreviewConfig,
  paris: parisPreviewConfig,
  saoPaulo: saoPauloPreviewConfig,
  sydney: sydneyPreviewConfig,
  tokyo: tokyoPreviewConfig,
};
