import { templateThemeTokens } from './templateThemeTokens.js';
import { THEME_DEFAULT_FONTS } from '@/contexts/CustomThemeConfigContext/constants.js';
import { createTheme } from '@/stitches/index.js';
import { merge } from 'lodash-es';

const mergedTokens = merge(templateThemeTokens, {
  colors: {
    defaultFont: '$white',
    pageBackground: '#335ABF',
  },
  fonts: {
    accent: THEME_DEFAULT_FONTS.madrid.dawn.accent
      ? THEME_DEFAULT_FONTS.madrid.dawn.accent.value
      : 'unset',
    body: THEME_DEFAULT_FONTS.madrid.dawn.body.value,
    heading: THEME_DEFAULT_FONTS.madrid.dawn.headline.value,
  },
});

export const dusk = createTheme({ ...mergedTokens });
