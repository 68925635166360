import { templateThemeTokens } from './templateThemeTokens.js';
import { THEME_DEFAULT_FONTS } from '@/contexts/CustomThemeConfigContext/constants.js';
import { createTheme } from '@/stitches/index.js';

export const dawn = createTheme({
  ...templateThemeTokens,
  colors: {
    cardGradient1: 'rgba(255, 255, 255, 0.21)',
    cardGradient2: 'rgba(255, 255, 255, 0.07)',
    defaultFont: '$brandBlack',
    inverseDefaultFont: '$white',
    pageBackground: '#EFEAFC',
    secondaryFont: 'rgba(145, 152, 189, 0.28)',
    sectionBackground: 'rgba(255, 255, 255, 0.3)',
  },
  fonts: {
    accent: THEME_DEFAULT_FONTS.tokyo.dawn.accent
      ? THEME_DEFAULT_FONTS.tokyo.dawn.accent.value
      : 'unset',
    body: THEME_DEFAULT_FONTS.tokyo.dawn.body.value,
    heading: THEME_DEFAULT_FONTS.tokyo.dawn.headline.value,
  },
});
