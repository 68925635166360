import { type TemplateEdges, type Templates, type Theme } from '../types.js';
import { isSupportedTemplateSlug } from './isSupportedTemplateSlug.js';
import { isSupportedThemeSlug } from './isSupportedThemeSlug.js';

export const buildTemplates = (templates: TemplateEdges): Templates => {
  const builtTemplates = templates
    .filter(({ node }) => {
      const hasThemes = Boolean(node.themes.edges.length);
      if (!hasThemes) {
        // eslint-disable-next-line no-console
        console.warn(
          `Filtering out ${node.displayName} template as it has no available, live themes.`,
        );
      }

      return hasThemes;
    })
    .map(({ node }) => {
      const builtThemes = node.themes.edges.map(({ node: themeNode }) => ({
        ...themeNode,
      }));
      const validThemes = builtThemes.filter(({ developmentSlug }) =>
        isSupportedThemeSlug(developmentSlug),
      ) as Theme[];

      return {
        ...node,
        themes: validThemes,
      };
    });

  const validTemplates = builtTemplates.filter(({ developmentSlug }) =>
    isSupportedTemplateSlug(developmentSlug),
  ) as Templates;
  return validTemplates;
};
